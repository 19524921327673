import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

import HeaderSEO from '../../components/partials/HeaderSEO'
import Layout from '../../components/Layout'

import { StaticImage } from 'gatsby-plugin-image'
import TransitionLink from 'gatsby-plugin-transition-link'
import gsap from 'gsap'

const kafkaMonitoringThankYou = ({ transitionStatus }) => {
  const data = useStaticQuery(graphql`
    query Kafka_Monitoring_Thank_you {
      allContentfulPages(
        filter: {
          pageReference: {
            pageIdentifier: { eq: "Kafka_Monitoring_Thank_you" }
          }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  useEffect(() => {
    // Set here to Prevent doubleScrollbar
    document.querySelector<HTMLElement>('.tl-edges').style.overflowX = 'hidden'
  }, [])

  useEffect(() => {
    if (transitionStatus === 'exiting') {
      // Entering Intro section
      gsap.fromTo('.Intro', { x: window.innerWidth }, { x: 0, duration: 0.83 })
    }
  }, [transitionStatus]) //execute when transitionStatus change

  return (
    <Layout type="KafkaMonitoring" onlyLogo={true} noFooter={true}>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
      </Helmet>
      <section className="bg-dark ">
        <div className="container-2 Landing hero-padding pb-4 mh-100">
          <div className="d-flex flex-wrap align-items-center">
            <div className="col-sm-7 col-11">
              <h2 className="mt-3 text-white f-55 lh-64 pr-2">
                All you need to know about Kafka monitoring & data observability
              </h2>
              <p className="mobile-margin-top-half">
                <TransitionLink
                  id="kafka-monitoring-guide-landing-to-intro"
                  className="light-blue-2-text text-underline f-28"
                  to="/guide/everything-need-know-about-kafka-monitoring/intro/"
                  exit={{ length: 0.8 }}
                >
                  Start reading
                </TransitionLink>
              </p>
            </div>
            <div className="col-sm-5 col-12 mobile-margin-top">
              <StaticImage
                className="img-fluid"
                src="../../resources/images/kafka_guide/hero.svg"
                placeholder="tracedSVG"
                alt="enterprise ok"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default kafkaMonitoringThankYou
